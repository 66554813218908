<template>
  <el-dialog
    custom-class="base-form"
    :visible.sync="dialogVisible"
    :width="width"
    :v-on="$listeners"
    >
    <span slot="title" style="text-align: left">
      <h3>{{ title }}</h3>
    </span>
    <div class="body">
       <el-table
        v-loading="tableLoading"
        :border="true"
        :data="tableData"
        :header-cell-style="{ background: '#F4F6FA', alignSelf: 'center',textAlign: 'center',fontSize:'13px'}"
        :cell-style="{alignSelf: 'center',textAlign: 'center',padding:'5px',fontSize:'12px'}"
        >
        <el-table-column type="expand">
          <template e v-slot="scope">
            <div class="div-frac-left-right">
              <div class="flex-row">
                <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed." placement="top">
                  <i class="el-icon-question" style="color:#c0c0c0"></i>
                </el-tooltip>
                <p class="font-small">Transaction Hash:</p>
              </div>
              <p class="font-small blackchain-line-info">{{scope.row.transactionHash}}</p>
            </div>
            <!-- <div class="div-frac-left-right">
              <div class="flex-row">
                <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="Top Center 提示文字" placement="top">
                  <i class="el-icon-question" style="color:#c0c0c0"></i>
                </el-tooltip>
                <p class="font-small">Block Hash:</p>
              </div>
              <p class="font-small blackchain-line-info">{{scope.row.blockHash}}</p>
            </div> -->
            <div class="div-frac-left-right">
            <div class="flex-row">
              <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="The status of the transaction." placement="top">
                <i class="el-icon-question"  style="color:#c0c0c0"></i>
              </el-tooltip>
              <p class="font-small">Status:</p>
              </div>
             <div class="status-tag">
                <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="A Status code indicating if the top-level call succeeded or failed (applicable for Post BYZANTIUM blocks only)" placement="top">
                 <el-tag size="small"  v-if="scope.row.status ===true" type="success"><i class="el-icon-success" style="color:green;padding-right:5px"></i> Success</el-tag>
                <el-tag size="small" v-if="scope.row.status ===false" type="danger"><i class="el-icon-error" style="color:red;padding-right:5px"></i> Unsuccessful</el-tag>
                </el-tooltip>
              </div>
            </div>
            <div class="div-frac-left-right">
              <div class="flex-row">
                <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was mined." placement="top">
                  <i class="el-icon-question"  style="color:#c0c0c0"></i>
                </el-tooltip>
                <p class="font-small">Block:</p>
              </div>
              <p class="font-small blackchain-line-info">{{scope.row.blockNumber}}</p>
            </div>
            <div class="div-frac-left-right">
              <div class="flex-row">
                <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="The date and time at which a transaction is mined." placement="top">
                  <i class="el-icon-question" style="color:#c0c0c0"></i>
                </el-tooltip>
                <p class="font-small">Timestamp:</p>
              </div>
              <p class="font-small blackchain-line-info" >{{formatUtcTimeString(scope.row.updatedAt)}} UTC</p>
            </div>
            <div class="div-frac-left-right">
              <div class="flex-row">
                <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="The sending party of the transaction." placement="top">
                  <i class="el-icon-question" style="color:#c0c0c0"></i>
                </el-tooltip>
                <p class="font-small">From:</p>
              </div>
              <p class="font-small blackchain-line-info" >{{ scope.row.fromAddress}}</p>
            </div>
            <div class="div-frac-left-right">
              <div class="flex-row">
                <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="The receiving party of the transaction (could be a contract address)." placement="top">
                  <i class="el-icon-question" style="color:#c0c0c0"></i>
                </el-tooltip>
                <p class="font-small">Interacted With (To):</p>
              </div>
              <p class="font-small blackchain-line-info" >Contract {{ scope.row.toAddress}}</p>
            </div>
            <div class="div-frac-left-right">
              <div class="flex-row">
                <el-tooltip class="item margin-right-8" popper-class="new-tooltip" effect="light" content="Cost per unit of gas specified for the transaction, in Ether and Gwei. The higher the gas price the higher chance of getting included in a block." placement="top">
                  <i class="el-icon-question" style="color:#c0c0c0"></i>
                </el-tooltip>
                <p class="font-small">Gas Used:</p>
              </div>
              <p class="font-small blackchain-line-info" >{{ scope.row.gasUsed}} Gwei</p>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="transactionAction" label="Transaction Action" min-width="100"> </el-table-column>
        <el-table-column prop="actionBy" label="Action By" min-width="80"> </el-table-column> -->
        <el-table-column prop="status" label="Status" min-width="100">
          <template v-slot="scope">
            <el-tag size="small" v-if="scope.row.status ===true" type="success">Success</el-tag>
            <el-tag size="small" v-if="scope.row.status ===false" type="danger">Unsuccessful</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="transactionHash" label="Transaction Hash" min-width="350"> </el-table-column>
        <el-table-column prop="blockNumber" label="Block Number" min-width="100">
           <template v-slot="scope">
            <el-link type="primary" >{{scope.row.blockNumber }}</el-link>
            <!-- <span @click={handleBlock(scope.row)} class="a-tag-text">{{scope.row.block}}</span> -->
          </template>
        </el-table-column>
        <el-table-column prop="updatedAt" label="Timestamp" min-width="200">
          <template slot-scope="scope">
              <span>{{formatUtcTimeString(scope.row.updatedAt)}}</span>
            </template>
        </el-table-column>
        <!-- <el-table-column prop="action" label="" min-width="40">
          <template v-slot="scope">
             <img class="image-icon"   @click={linkToTransactionDetails(scope.row)} src="../../../src/assets/icons/link-to-wurl.png">
          </template>
        </el-table-column> -->
        </el-table>
    </div>
    <div class="footer-button-bar" slot="footer">
      <el-button
        size="small"
        style="min-width: 100px"
        v-if="showCloseButton"
        round
        @click="handleClose"
      >
        {{ $t("Close") }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import baseTableMixin from '@/mixins/base-table-mixin'
import { formatNullToUnderscore, formatUtcTimeString } from '@/utils'
import { getBlockchain } from '@/services/modules/comm.js'
export default {
  name: 'DialogBlockchainInfo',
  mixins: [baseTableMixin],
  props: {
    title: {
      type: String,
      default: 'Blockchain Information'
    },
    propsData: {
      type: Object,
      require: true
    },
    width: {
      type: String,
      default: '90%'
    },
    showCloseButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      formData: null,
      submitLoading: false,
      dialogVisible: true,
      tableLoading: false,
      currentCompany: this.$store.state.currentCompany,
      tableData: []
    }
  },
  watch: {
    dialogVisible (val) {
      if (!val) {
        this.handleClose()
      }
    }
  },
  mounted () {
    this.getBlockChainInfo()
  },
  methods: {
    getBlockchain,
    formatNullToUnderscore,
    formatUtcTimeString,
    handleClose () {
      this.$emit('onClose', false)
    },
    handleSubmit (val) {
      const data = val || {}
      this.$emit('onsubmit', data)
    },
    getBlockChainInfo () {
      this.tableLoading = true
      this.getBlockchain(this.propsData).then(res => {
        if (res?.code === 1000) {
          this.tableData = res.data ? this.formatNullToUnderscore(res.data) : []
          this.tableLoading = false
          this.$message.success('Get data by id successful!')
        } else {
          this.$message.console.error('Get data by id faild!')
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },
    linkToTransactionDetails (data) {
      data.wurl = 'https://element.eleme.io/#/en-US/component/dropdown#dropdown'
      window.open(data.wurl)
    },
    handleBlock (data) {
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body{
  padding: 0 20px;
  // font-size: 12px;
}
.body {
  max-height: 55vh;
  overflow: scroll;
  .el-tag.el-tag--success{
    background-color: #d4f4d4;
    border-radius: 50px;
    min-width: 80px;
  }
  .el-tag.el-tag--danger{
    background-color: #f4d4d4;
    border-radius: 50px;
  }
  .a-tag-text, /deep/.el-link--inner{
    color:#0C86F6;
    font-size: 12px;
    // text-decoration: #0C86F6 underline;
  }
  .image-icon{
    margin-top: 5px;
    width: 30px;
    height: 30px;
    &:hover{
      cursor:pointer;
    }
  }

}
.footer-button-bar {
  padding: 0 15px;
}
.blackchain-line-info {
  text-align: left;
}
.status-tag {
//  width: 100px;
 padding-left: 0;
}

</style>
